<template>
<el-row>
    <el-col :span="4"></el-col>
    <el-col :span="17">
      <div class="info-top">
        <div style="flex: 1">
        <el-avatar
            size="large"
            style="height: 90px; width: 90px; "
            :src="'https://hsq.org.cn:8090/hsq/file/'+user.avatar"></el-avatar>
        </div>
        <div style="flex: 5" class="info-top-right">
          <div style="font-size:15px">

            <div style="padding: 0px">
              <el-row></el-row>
              <el-row>
              <el-col :span="15" >{{user.nickName}}<br>用户名:{{user.username}}<br>
                <el-button @click="showDialog=true" type="primary" size="small">修改密码</el-button></el-col>

              <el-col :span="2" class="info-top-right-col">{{user.novelcount}}<br>作品</el-col>
                <el-col :span="2" class="info-top-right-col">{{user.thumbsUpcount}}<br>点赞</el-col>
                <el-col :span="2" class="info-top-right-col">{{user.collectioncount}}<br>收藏</el-col>
              </el-row>
            </div>


          </div>


  </div>
      </div>
    </el-col>
   <el-col :span="3"></el-col>
</el-row>
  <br>
  <el-row>
    <el-col :span="4"></el-col>
    <el-col :span="3" style=" border:1px solid #000;">
      <el-divider />
      <div @click="t='userinfo'">个人信息</div>
      <el-divider />
      <div @click="t='novelList'">我的作品</div>
      <el-divider />
      <div @click="t='thumbsUpList'">我的点赞</div>
      <el-divider />
      <div @click="t='collectionList'">我的收藏</div>
      <el-divider />

    </el-col>

    <el-col :span="14" style=" border:1px solid #000; padding: 10px;">
      <div v-if="t=='userinfo'" class="userinfo">
        <table >
          <tr>
            <td>昵称：</td>
            <td><el-input v-model="user.nickName"/></td>
          </tr>
          <tr>
            <td>用户名：</td>
            <td><el-input v-model="user.username" disabled/></td>
          </tr>
          <tr>
            <td>真实姓名：</td>
            <td><el-input v-model="user.fullname"/></td>
          </tr>
          <tr>
            <td>性别：</td>
            <td>
              <el-radio-group v-model="user.sex" size="medium">
                <el-radio-button label="男" value="男" />
                <el-radio-button label="女" value="女" />
                <el-radio-button label="保密"  value="保密" />
              </el-radio-group></td>

          </tr>
          <tr>
            <td>年龄：</td>
            <td><el-input v-model="user.age" validate-event type="number"></el-input></td>
          </tr>
          <tr>
            <td>电话：</td>
            <td><el-input v-model="user.phoneNumber"/></td>
          </tr>
<!--          合并单元格-->
          <tr >
            <td colspan="2" style="text-align: center;"><el-button  @click="update">保存</el-button></td>
          </tr>



        </table>



      </div>
      <div v-if="t=='novelList'">
      <novel-card-list :novel-list="user.novelList" :isall="true"></novel-card-list>
    </div>
      <div v-if="t=='thumbsUpList'">
      <novel-card-list :novel-list="user.thumbsUpList" :isall="true"></novel-card-list>
    </div>
      <div v-if="t=='collectionList'">
      <novel-card-list :novel-list="user.collectionList" :isall="true"></novel-card-list>
    </div>
    </el-col>
    <el-col :span="3"></el-col>
   </el-row>
  <el-dialog v-model="showDialog" title="修改个人信息" width="30%">
    <el-form :model="pswdForm" label-width="80px" :rules="formRules" ref="pswdForm">
      <el-form-item label="旧密码" prop="oldPassword" >
        <el-input v-model="pswdForm.oldPassword" type="password"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input v-model="pswdForm.newPassword" type="password"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPassword">
        <el-input v-model="pswdForm.confirmPassword" type="password"></el-input>
      </el-form-item>


      <el-button type="primary" @click="submitForm">修改</el-button>
    </el-form>



  </el-dialog>
</template>

<style scoped>
.info-top {

  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  border:1px solid #000;
}
.info-top-right {
  text-align: left;


}
.info-top-right div {
  margin-bottom: 10px;
}
.info-top-right-col {
  text-align: center;padding-top: 20px;
}
.userinfo{
  padding: 20px;
  margin-top: 20px;
  margin-left: 20px;

}
.userinfo table{

  border-collapse: collapse;
}
.userinfo table td{

  padding: 10px;
}

</style>
<script>


import request from "@/utils/request";
import NovelCardList from "@/components/组件/NovelCard.vue";
import router from "@/router";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Info",
  components: {NovelCardList},
  data(){
    return{

      user:localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):{},

      params:{
        id:'',
        username:'',
        fullname:'',
        pageNum:1,
        pageSize:5
      },
      showDialog:false,
      t:'userinfo',
      formRules: {
        oldPassword: [
          {required: true,message: "请输入旧密码",trigger: "blur"},
          {validator: this.validateOldPassword,trigger: "blur"}
        ],
        newPassword: [
          {required: true,message: "请输入新密码",trigger: "blur"},
          {min: 6,message: "密码长度不能小于6位",trigger: "blur"}
        ],
        confirmPassword: [
          {required: true,message: "请输入确认密码",trigger: "blur"},
          { validator: this.validateConfirmPassword, trigger: 'blur' }

  ]
      },
      pswdForm: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      }

      }


  },
  mounted() {
this.getUserInfo();


  },
  methods:{
    getUserInfo(){
      request.get('/user/getUserInfo/'+this.user.id).then(res => {
        this.user = res.data;



      })
    },

    // 自定义确认密码验证函数
    validateConfirmPassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入新密码'));
      } else if (value !== this.pswdForm.newPassword) {
        callback(new Error('两次输入密码不一致'));
      } else {
        callback();
      }
    },
    validateOldPassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入旧密码'));
      } else if (value !== this.user.password) {
        callback(new Error('旧密码错误'));
      } else {
        callback();
      }
    },
    update(){
      request.post('user/save',this.user).then((res) => {
        if (res.code === '1') {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.showDialog = false;



        }else {
          this.$message.error(res.msg);
          this.getUserInfo();
        }
      })
    },

     submitForm() {
      this.$refs['pswdForm'].validate(valid => {
        if (valid) {
          // 验证通过，执行修改逻辑
          this.user.password = this.pswdForm.newPassword;
          request.post('user/save',this.user).then((res) => {
            if (res.code === '1') {
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.showDialog = false;
              this.pswdForm.oldPassword = '';
              this.pswdForm.newPassword = '';
              this.pswdForm.confirmPassword = '';
              router.push('/');

              localStorage.clear();


            }else {
              this.$message.error(res.msg);
            }
          })







        } else {
          // 验证不通过，不执行修改逻辑，可以显示错误信息或者其他处理

          return false;
        }
      });
    },
    handleClose() {
      this.showDialog = false;
    },


  },
}
</script>