<style scoped>
.foot{
  overflow-y:auto;
  z-index: -1;
  background-color: #425254;
  position: fixed;

   bottom:0;
  right: 0;
  width: 80%;
  height: 50px;


}

.subimbutton{
  position: fixed;
  bottom: 2%;
  right: 2%;

}
.getimg{
  position: fixed;
  bottom: 2%;
  left: 22%;
  text-align: center;
}

.el-input{
  width: 62%;
  position: fixed;

  bottom:1%;
  left: 28%;

}

.p{
  display: block;
  padding: 1px;
  border-radius: 10px;

  color: #333;
  max-width: 60%;
  word-wrap: break-word;


margin-right: 20px;
  margin-bottom: 1px; /* 添加 margin-left 属性 */
}
.scrollable-aside {

  position: fixed;
  overflow-y: auto; /* 添加滚动样式 */
  height: 100%; /* 填充父容器高度 */
  align-items: right;

}
.main-content{

  overflow-y: auto;
  position: fixed; /* 子元素设置为绝对定位 */
  z-index: 1;
  height: 100%; /* 填充父容器高度 */
  top: 60px;
  right: 0; /* 将侧边栏放在右侧 */


}
</style>
<template>
  <div class="common-layout">
    <el-container>

      <el-aside width="20%" class="scrollable-aside">
        <el-scrollbar style="background-color: transparent;">
          <el-menu v-for="user in from" :key="user.username">
            <el-menu-item @click="receiveMessages(user.id);value=user.username;">
              <div>
              <el-badge :value="notread[user.id] || ''" class="item" :offset="[-10, 25]">
                {{user.username}}
              </el-badge>
              </div></el-menu-item>
          </el-menu>
        </el-scrollbar>
      </el-aside>

      <el-container>
        <el-main style="position: fixed;top: 200px">

          <div class="main-content" style="width: 77%;height: 85%;margin-bottom: 80%"  ref="mainContent">
            <div style="text-align: center;position: fixed;top: 60px;width: 100%;right: 0px">你正在和{{value}}聊天</div>
            <div v-for="(msg,index) in messages" :key="index" style="text-align: left; top: 100px">
              <div style="clear: both;">
              <p class="p" :style="'padding: 8px; float: ' + (msg.sender == this.user.id ? 'right' : 'left') + '; display: block;'+'background-color:'+(msg.sender == this.user.id ? 'lawngreen' : '#7c989f')">


                <el-text style="color: black;">{{ msg.message }}</el-text>
                <br/>
                <el-image v-if="msg.img"
                    style="width: 100px; height:120px"
                    :src="'https://hsq.org.cn:8090/hsq/file/'+msg.img"
                    :preview-src-list=getPreviewSrcList(msg.img)

                />
                <br/>

                <span style="background-color: white" v-if="msg.file">
                <el-text >文件</el-text><br/>
                <el-text id="fname" @click="getfilename(msg.file,index)">文件</el-text>
                  &nbsp;&nbsp; <el-link type="primary" :href="'https://hsq.org.cn:8090/hsq/file/'+ msg.file">下载</el-link>

                </span>

              </p>
            </div>
            </div>
          </div>
          <div style="top: 82%; left:20%;position: fixed;z-index: 999">
            <ul>
              <li v-for="file in customFileList" :key="file.uid">
                {{file.type}}&nbsp;&nbsp;{{ file.name }}
              </li>
            </ul>
          </div>

        </el-main>

        <el-footer>
          <div class="footer-content">
            <div class="foot">
<!--              弹出框-->
              <div class="getimg">
              <el-popover placement="top" :width="160" style="align-items: center">
                <span id="startlisten">按住'左Ctrl'开始录音</span><br/>
                <div >
                  <el-upload ref="imgRef"
                      class="avatar-uploader"
                      action="https://hsq.org.cn:8090/hsq/file/imgupload"
                      show-file-list:false
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                      :limit="1"
                      :on-exceed="handleExceed"
                  >
                    <template v-slot:trigger>

                      <span>
                      <el-icon><Picture /></el-icon>
                     选择图片
                </span>
                    </template>

                  </el-upload>
                </div>
                <div>
                  <el-upload  ref="fileRef"
                      class="avatar-uploader"
                      action="https://hsq.org.cn:8090/hsq/file/fileupload"
                      show-file-list:false
                      :on-success="fileSuccess"

                      :limit="1"
                      :on-exceed="handleExceed"
                  >
                    <template v-slot:trigger>
                      <div>
                        <span>
                        <el-icon><Files /></el-icon>
                          选择文件
                     </span><br/>
                      </div>

                    </template>

                  </el-upload>
                </div>
                <template #reference>
                  <el-button @click="visible = true" size="small">选择文件</el-button>
                </template>
              </el-popover>
              </div>
              <el-input v-model="receiveuser.message" id="input" placeholder="输入不能超过50字"></el-input>
              <div class="subimbutton">
                <el-button @click="send" style="background-color: lawngreen">send</el-button>
              </div>
            </div>
          </div>
        </el-footer>
      </el-container>
    </el-container>
  </div>





</template>

<script>
import request from "@/utils/request";


export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Message',

  data(){
    return{
      notread:"",
        from:{},
      value:"",
      messages:[],
      params:{
        username:'',
        fullname:'',
        pageNum:1,
        pageSize:500
      },
      receiveuser:{
        data:"",
        receiver:"",
        message:"",
        sender:"",
        img:"",
        file:""


      },
      Selectimage:false,
      user:localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):{},
      web:{},
      customFileList: [],
      filelink:"",
      list:[]
    }
  },


  methods:{

    //获取用户
    getusers(){


      request.post("/user/get",this.params).then(res=>{
        if (res.code === '1') {

          this.from = res.data.list;
          const requestData = {
            users: this.from,
            user: this.user
          };
          request.post("/msg/g",requestData).then(res=>{
                this.notread=res.data;
                console.log(this.notread['hsq'])
              }
          )
        } else {
          this.$message({
            message:res.msg,
            type: 'error'
          });
        }
      })

    },
    getPreviewSrcList(imgUrl) {
      return ['https://hsq.org.cn:8090/hsq/file/' + imgUrl];
    },
    beforeAvatarUpload(file) {
      const isJPGorPNG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt3M = file.size / 1024 / 1024 < 3;

      if (!isJPGorPNG) {
        this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
        return false;
      }
      if (!isLt3M) {
        this.$message.error('上传头像图片大小不能超过 3MB!');
        return false;
      }

      return true;
    },
    //获取文件名
    getfilename(file,index){
      const message=this.messages;
      let newIndex = index;
      for (let i = 0; i <= index; i++) {
        if (!message[i].file) {
          newIndex=newIndex-1;
        }
      }
      setTimeout(() => {
        const onurl='https://hsq.org.cn:8090/hsq/file/'+file;
        const fnames = document.querySelectorAll('#fname');
        fetch(onurl)
            .then(response => {

              const contentDisposition = response.headers.get('content-disposition');
              const [, fileName] = contentDisposition.split(';').map(item => item.trim());
              const name = decodeURIComponent(fileName);
              const link = name.substring(name.indexOf("=") + 1);

              fnames[newIndex].textContent = link;
            })
      },300)
    },
    //文件超过上传数量
    handleExceed(){
      this.$message.error('上传文件数量不能超过1!');
    },
    //获取历史消息
    receiveMessages(users){

    this.receiveuser.receiver=users;

      if (this.web.readyState === WebSocket.OPEN) {
        this.web.close()
      }

      const url="ws://localhost:8090/chat/"+this.user.id+"/"+users;
      var socket = new WebSocket(url)
      const toUser=this.user.id
      const FromUser=users

      this.web=socket;
      this.web.onopen = function () {
        console.log("连接成功");



      };
      this.getcon(toUser,FromUser);

      request.post("/msg/readed?toUser="+toUser+"&FromUser="+FromUser)
      this.getusers();
      const mainContent = this.$refs.mainContent;
      this.web.onmessage = () => {

        this.l(); // 调用 l 方法
        this.getusers();
        setTimeout(function() {
          mainContent.scrollTop = mainContent.scrollHeight;
        }, 100);
      };
      this.web.onerror=function (){

      }

      this.web.onclose = function () {
        console.log("WebSocket Connection Closed");
      };

      request.post("/msg/getmessage",this.receiveuser).then(res=>{
        if (res.code === '1') {
          this.messages = res.data;
        } else {
          this.receiveuser.receiver=""
          this.$message({
            message:res.msg,
            type: 'error'
          });
        }
      })


    },
    l(){
      request.post("/msg/getmessage",this.receiveuser).then(res=>{
        if (res.code === '1') {
          this.messages = res.data;
        } else {
          this.receiveuser.receiver=""
          this.$message({
            message:res.msg,
            type: 'error'
          });
        }
      })
    },
    //上传成功
    handleAvatarSuccess(res,file) {
      // 上传成功后，从服务器返回的数据中获取文件 URL
      // 文件上传成功的处理逻辑
      file.type='图片'
      this.customFileList.push(file);
      this.receiveuser.img=res.data;


    },
    fileSuccess(res,file){
      this.fileurl='https://hsq.org.cn:8090/hsq/file/'+res.data;
      file.type='文件'
      this.customFileList.push(file);
      this.receiveuser.file=res.data;

    },
    //发送消息
    send(){
      const mainContent = this.$refs.mainContent;
      if(this.receiveuser.message!=""){
      const date = new Date();
      this.receiveuser.data = date.toLocaleString();
        var message = JSON.stringify(this.receiveuser.message);
        this.web.send(message);
        this.$refs.imgRef.clearFiles();
        this.$refs.fileRef.clearFiles();
      request.post("/msg/save",this.receiveuser).then(res=> {
        if (res.code === '1') {

          this.$message({
            message: "发送成功",
            type: 'success'
          });
          this.receiveuser.message="";
          this.receiveuser.img="";
          this.customFileList=[];
          this.receiveuser.file="";
          this.receiveMessages(this.receiveuser.receiver)




        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })
    }else{
        this.$message({
          message: "输入框不能为空",
          type: 'error'
        });
      }
      setTimeout(function() {
        mainContent.scrollTop = mainContent.scrollHeight;
      }, 100);
    },
    chehui(id){
      request.get("/msg/delete",id).then(res=>{
        if (res.code === '1') {

          this.from = res.data.list;
        } else {
          this.$message({
            message:res.msg,
            type: 'error'
          });
        }
      })

    },
    //录音
    listenstart(){   // 录音部分
      setTimeout(() => {
        const recognition = new window.webkitSpeechRecognition();
        recognition.lang = 'zh-CN';
        // 创建变量来表示是否正在录音
        let isRecording = false;
        const inputElement = document.getElementById('input');
        const listenElement = document.getElementById('startlisten');
// 监听键盘按下事件
        document.addEventListener('keydown', function(event) {
          // 检查按下的键是否为空格键，并且当前不在录音状态

          if (event.code === 'ControlLeft' && !isRecording) {
            setTimeout(() => {
            recognition.start();},500);
            listenElement.textContent="正在录音"
            isRecording = true;
          }
        });

// 监听键盘抬起事件
        document.addEventListener('keyup', function(event) {
          // 检查抬起的键是否为空格键，并且当前在录音状态
          if (event.code === 'ControlLeft' && isRecording) {

            recognition.stop();
            listenElement.textContent="按'左Ctrl'开始录音"
            isRecording = false;
          }
        });

// 监听语音识别结果
        recognition.onresult = (event)=>{
          const transcript = event.results[0][0].transcript;
          console.log('识别结果:', transcript);
          // 将识别结果添加到屏幕元素中
          this.receiveuser.message+=transcript;
          inputElement.value += transcript;
        };

// 监听语音识别错误
        recognition.onerror = function(event) {
          console.error('语音识别错误:', event.error);
        };

// 停止语音识别
        recognition.onend = function() {
          console.log('语音识别结束');
        };


      },1000)},
    getcon(toUser,FromUser){

      this.obj = {
        [toUser]: FromUser
      };

      this.list.push(this.obj);

      // 查找数组中相应的对象并更新值
      const index = this.list.findIndex(item => item[toUser] === FromUser);
      if (index !== -1) {
        this.list[index][toUser] = this.obj[toUser];
      }



    }
  },
  watch: {
    //监听数组中msg.file的变化
    messages: {
      deep: true,
      handler(newVal) {
        newVal.forEach((msg) => {
          if (msg.file != null) {
            setTimeout(() => {
              const fnames = document.querySelectorAll('#fname');
              fnames.forEach((fname) => {
                fname.click();
              });
            }, 400);
          }
        });
      }
    },
  },
  mounted() {
    this.getusers();

    this.receiveuser.sender=this.user.id;
    this.listenstart();
  },

}
</script>