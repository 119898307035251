<style scoped>
.el-input{

  width: 200px;
  margin-top:0px ;
}
.container {
  position: absolute;
   height: 300px;
  width: 350px;
  background-color: rgb(155, 211, 218);
  top:10%;
  left: 9%;

  margin: auto;

}


.login-title{
margin-top: 1%;
  background-color: aqua;
}
.box{
  position: relative; /* 设置容器为相对定位 */
  height: 430px;
  width: 420px;
  background-color: rgb(155, 211, 218);
  margin: auto;
  margin-top: 10%;
}
</style>
<template>

  <div >
    <div class="box">
      <div class="container" v-if="Type=='LOGIN'">


  <h1><p>登录</p></h1>
        <el-form :model="input"  label-width="80px">
          <el-form-item label="用户名" prop="username">
            <el-input v-model="input.username" autocomplete="off" />
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="input.password" type="password" autocomplete="off" />
          </el-form-item>

        </el-form>


      <el-button @click="Ifonline">登<img src="../../assets/1.jpg" height="48" width="60"/>录</el-button>
  <br><a style="color: blue" @click="getType">没有账号，点击此处去注册</a>
     <br>   <a href="/codelogin">扫码登录</a>
</div>

      <div class="container" v-if="Type=='REGISTER'">

        <h1><p>注册</p></h1>
        <el-form-item label="用户名" :label-width="formLabelWidth">
          <el-input v-model="register.username" autocomplete="off" />

        </el-form-item>

        <el-form-item label="密码" :label-width="formLabelWidth">

          <el-input v-model="register.password"  type="password" @change="v" autocomplete="off" >


          </el-input>

          <span  v-if="error" style="color: red; font-size: 12px;">密码必须为6-16位数字或字母</span>

        </el-form-item>

        <el-form-item label="真实名" :label-width="formLabelWidth">
          <el-input v-model="register.fullname" autocomplete="off" />
        </el-form-item>

        <el-button @click="regist">注<img src="../../assets/1.jpg" height="48" width="60"/>册</el-button>
        <p></p><a style="color: blue" @click="getType">以有账号，点击此处去登录</a>
      </div>
</div>
 </div>


</template>



<script>


import request from "@/utils/request";


export default {

  // eslint-disable-next-line vue/multi-word-component-names
  name: 'login',
  // 页面加载的时候，做一些事情，在created里面


  data() {
    // 验证码校验

    return {
      captchaUrl: "",
      key: "",
      input: {
        username:'',
        password:'',

      },

      register:{
        username:'',
        password:'',
        fullname:'',
      },
       Type:"LOGIN",
      formLabelWidth:'30%',

error:false,

    }

  },


  methods: {
v(){
  let userNameReg = /^(?![0-9]+$)[0-9A-Za-z|a-zA-Z]{6,16}$/;
  if (!userNameReg.test(this.register.password)) {
    this.error=true
    return false;
  }else {
    this.error = false
    return true;
  }
},
    login() {

      if (this.input.username == '' | this.input.password == '') {
        this.$message({
          message: '账号或密码不能为空',
          type: 'error'
        });
      } else {
        request.post("/user/login" , this.input).then(res => {
          if (res.code == 1) {
            this.$message({
              message: '登录成功',
              type: 'success'
            });
            request.post("/onlineusers/save",this.input)

            localStorage.setItem("user", JSON.stringify(res.data));

            this.$router.push('/LayOut');

          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            });


          }
        })
      }
    },
    Ifonline(){
      request.post('/onlineusers/getonline',this.input).then(res=>{
        if(res.code=="1"){
          this.login();

        }else{
          this.$message({
            message:res.msg,
            type:"error"
          })
        }
      })

    },
getType(){
     if(this.Type=="LOGIN"){
       this.Type="REGISTER"
       this.$message({
         message: '请注册',
         type: 'success'
       });

     }
     else {
       this.Type="LOGIN"
       this.$message({
         message: '请登录',
         type: 'success'
       });

     }
},

    regist(){

       {
         if (this.register.username == '' | this.register.password == '') {
           this.$message({
             message: '账号或密码不能为空',
             type: 'error'
           });
         } else {
           request.post('/user/regist', this.register).then(res => {
             if (res.code == 1) {
               this.$message({
                 message: '注册成功,请登录',
                 type: 'success'
               });
               this.register = {},
                   this.Type = "LOGIN"


             } else {
               this.$message({
                 message: res.msg,
                 type: 'error'
               });

             }
           })
         }
       }

    }

  },
  mounted() {

  }
}
</script>
