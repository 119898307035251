
<style scoped>
.el-divider--horizontal{
  margin-top: 10px;
}
.box{
margin-right: 10px;
}

</style>
<template>
  <div style="width: 50%;background-color: #d5dfea;position: relative;left: 25%;padding: 1%" >
    <div v-html="txt"></div>


  </div>


  <div style="width: 50%;position: relative;left: 25%;disply:flex; text-align: left;padding: 1%;background-color: #fcecec" >
    <div>
      <span style="text-align: start">评论区</span>

    <div style="display: flex">
      <div style="padding: 1px;">
        <el-avatar
            src="https://hsq.org.cn:8090/hsq/file/1710508278683"
        />
      </div>
      <el-input size="default" style="flex: 1" @focus="onFocus" v-model="editor" placeholder="请输入评论内容" >

      </el-input>

      <button @click="comment()">提交</button>
    </div>
    <div v-if="toolbar" style="position:relative;left:8%">
      <div class="el-icon-edit" style="display:flex;color: #909399; ">
        <el-popover :visible="visible" placement="bottom" :width="160" >
           <div ><span v-for="(item,index) in emojiList" :key="index" @click="editor+=item">{{item}}</span></div>
          <template #reference>
            <div style="text-align: center;font-size: small; margin-right: 5px" @click="visible = true" >😍
              <br>表情</div>
          </template>
        </el-popover>


        <div style="text-align: center;font-size: small">😍
          <br>表情</div>
        <div style="flex: 1;">123</div></div>
    </div>
    </div>
      <div v-for="(item,index) in commentList" :key="index" @click="toolbar=false">

       <div style="display: flex; margin-bottom: 10px;">
        <div style="padding: 5px;">
          <el-avatar
              :src="'https://hsq.org.cn:8090/hsq/file/'+item.user.avatar"
          />
        </div>
        <div style="flex: 1; text-align:left; padding: 5px; margin-top: 5px;">
        <span>{{item.user.nickName==null?'用户'+item.user.username:item.user.nickName}}:</span>
        <div style="margin: 5px 0 5px 0px;">{{item.content}}</div>
          <div style="display: flex;  margin-top: 5px;font-size:small;">{{item.creattime}}
            <div class="box"><img src="../../assets/icon/thumbsup.png" style="width: 15px;height: 15px;margin-right: 5px;" @click="commentthumbsup(item)" >{{item.thumbsUp==0?'':item.thumbsUp}}</div><a @click="reply(item);item.s=true;" style="margin-right: 5px; color: #409eff;" @mousemove="mouseover" @mouseout="mouseout">回复</a></div>
          <div v-if="item.s" >
            <el-input size="small" style="flex: 1"  v-model="item.replyContent" :placeholder="huifu" >

          </el-input>
            <button @click="replyitem(item)">提交</button>
          </div>
          <!--        回复-->
          <div style=" margin-bottom: 10px;position: relative;transform: scale(0.95);" @mousemove="this.visible=false">

            <div v-for="i in item.reply" :key="i.id" style="" >

              <div  style="display: flex; margin-bottom: 10px;">
                <div style="padding: 5px;">
                  <el-avatar
                      :src="'https://hsq.org.cn:8090/hsq/file/'+i.user.avatar"
                  />
                </div>
                <div style="flex: 1; text-align:left; padding: 5px; margin-top: 5px;">
                  <span>{{i.user.nickName==null?'用户'+i.user.username:i.user.nickName}}:</span>
                  <div style="margin: 5px 0 5px 0px;">
                    <div v-if="i.parentReplyId!=item.reply.id">回复@{{i.user.nickName==null?'用户'+i.user.username:i.user.nickName}}:{{i.content}}</div>
                  <div v-else>{{i.content}}</div></div>
                  <div style="display: flex;  margin-top: 5px;font-size:small;">
                    <div class="box">{{i.creattime}}</div>
                    <div class="box"><img src="../../assets/icon/thumbsup.png" style="width: 15px;height: 15px;margin-right: 5px;" @click="replythumbsup(i)" >{{i.thumbsUp==0?'0':i.thumbsUp}}</div>


                    <div class="box"><a @click="reply(item);i.s=true;" style="margin-right: 5px; color: #409eff;" @mousemove="mouseover" @mouseout="mouseout">回复</a></div>
                    </div>
                  <div v-if="i.s">
                    <el-input size="small" style="flex: 1" @focus="onFocus" v-model="i.replyContent" placeholder="请输入回复内容" >

                    </el-input>
                    <button @click="replyComment(i,item)">提交</button>
                  </div>



                  </div>

                </div>


            </div>



          </div>
        </div>
       </div>

        <el-divider />
      </div>


  </div>

</template>


<script>
import request from "@/utils/request";
import emoji from "@/utils/emoji";
export default {
  name: "NovelContent",
  data(){
    return{
      txt:"",
      id:"",
      editor:'',
      toolbar:'',
      chapter:{},
      commentList:[],
      huifu:'',
      visible:false,
      user: JSON.parse(localStorage.getItem('user') || '{}'),
      emojiList:emoji,
    }
  },
  methods:{
onFocus(){
  this.toolbar=true;
},
reply(item){
  this.huifu='回复@'+item.user.username+' ';
},
replyComment(item) {
  request.post('/reply/add', {
    uid: this.user.id,
    content: item.replyContent,
    creattime: new Date(),
    commentId: item.commentId,
    parentReplyId: item.id


  }).then(res => {
   if(res.code=='1'){
     this.$message.success('回复成功');
     this.initData();
   }


  });

  item.replyContent = '';
  item.s = false;

},
    replyitem(item) {

      request.post('/reply/add', {
        uid: this.user.id,
        content: item.replyContent,
        creattime: new Date(),
        commentId: item.id,
        parentReplyId: NaN


      }).then(res => {
        if(res.code=='1'){
          this.$message.success('回复成功');
          this.initData();
        }


      });

      item.replyContent = '';
      item.s = false;

    },
    comment() {

      request.post('/comment/add', {
        uid: this.user.id,
        content: this.editor,
        creattime: new Date(),
        chapterid: this.id,

      }).then(res => {
        if (res.code == '1') {
          this.$message.success('评论成功');
          this.initData();
          this.editor = '';
        }
      });
    },
    initData()
    {

      var Chaptercontent = JSON.parse(localStorage.getItem('chaptercontent'));
      var ChapterId = localStorage.getItem('chapterId');


      this.txt = Chaptercontent;
      this.id = ChapterId;
      this.chapter.id = ChapterId;

      request.get('/comment/selectBychapterId/' + ChapterId).then(res => {
        if (res.code == '1') {
          this.commentList = res.data;
        }
        else {
          this.commentList = [];
          this.$message.error(res.msg);
        }
      });
    },
    commentthumbsup(item) {
      request.get("/thumbsUp/selectPage", {
        params: {
          uid: this.user.id,
          likeableId: item.id,
          type: "comment",

        }
      }).then(res => {
        if (res.code === "1") {
          if (res.data.list.length > 0) {
            request.delete("/thumbsUp/delete/"+res.data.list[0].id).then(res => {
              if (res.code === "1") {
                this.$message.success('取消点赞')
                this.initData();
              }
            })
          }
          else {
            request.post("/thumbsUp/add", {
                  uid: this.user.id,
                  likeableId: item.id,
                  type: "comment"
                }
            ).then(res => {
              if (res.code === "1") {
                this.$message.success('点赞成功')
                this.initData();
              }

              else {
                this.$message.error(res.msg)

              }

            })

          }

        } else {
          this.$message.error(res.msg)
        }
      })
            },

    replythumbsup(item) {

      request.get("/thumbsUp/selectPage", {
        params: {
          uid: this.user.id,
          likeableId: item.id,
          type: "reply",

        }
      }).then(res => {
        if (res.code === "1") {
          if (res.data.list.length > 0) {
            request.delete("/thumbsUp/delete/"+res.data.list[0].id).then(res => {
              if (res.code === "1") {
                this.$message.success('取消点赞')
                this.initData();
              }
            })
          }
          else {
            request.post("/thumbsUp/add", {
                  uid: this.user.id,
                  likeableId: item.id,
                  type: "reply"
                }
            ).then(res => {
              if (res.code === "1") {
                this.$message.success('点赞成功')
                this.initData();
              }

              else {
                this.$message.error(res.msg)

              }

            })

          }

        } else {
          this.$message.error(res.msg)
        }
      })
    },
    mouseover(e) {
      e.target.style.color = '#ff4083';
    },
    mouseout(e) {
      e.target.style.color = '#409eff';

    }

  },


  mounted() {
this.initData();

  }

}
</script>
