import axios from "axios";

import { store } from "./store.js";
const request = axios.create({
    baseURL: 'https://hsq.org.cn:8090/hsq',
    timeout: 5000
});

// 请求拦截器
request.interceptors.request.use(config => {
    // 在请求开始时记录当前时间
    store.commit('setLoading', true); // 设置 loading 为 true
   

    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    const user = localStorage.getItem('user');
    if (user) {
        config.headers['token'] = JSON.parse(user).token;
    }

    return config;
}, error => {
    return Promise.reject(error);
});

// 响应拦截器
request.interceptors.response.use(response => {
    // 在这里处理响应数据，可以添加监控逻辑
    let res = response.data;
    if (typeof res === 'string') {
        res = res ? JSON.parse(res) : res;
    }

    // 在这里添加监控逻辑，如记录响应时间、输出日志等

    store.commit('setLoading', false); // 设置 loading 为 true

    return res;
}, error => {
    console.log('err' + error);

    // 可以根据错误情况进行相应的处理，例如显示错误信息等

    return Promise.reject(error);
});

export default request;