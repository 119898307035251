<template>
  <div id="home">


    <el-input v-model="params.username" clearable style="width: 200px" placeholder="请输入用户名" />
    <el-input v-model="params.fullname" clearable style="width: 200px" placeholder="请输入真实姓名"/>
    <el-button @click="findSearch()">查询</el-button>
    <el-button @click="showDialog=true">添加</el-button>
    <el-button @click="exp()">导出用户表</el-button>
      <el-table v-loading=loading :data="items" style="width: 100%">
        <el-table-column prop="username" label="用户名" width="180" />
        <el-table-column prop="fullname" label="真实名" width="180" />
        <el-table-column prop="permission" label="权限" />
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button type="success" plain  @click="getuser(scope.row)">修改</el-button>


            <el-popconfirm @confirm="deleteItem(scope.row)" title="Are you sure to delete this?">
              <template #reference>
                <el-button id="delete" type="danger" plain >删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

    <el-pagination
        v-model:current-page="params.pageNum"
        v-model:page-size="params.pageSize"
        :page-sizes="[5, 10, 15, 20]"

        layout="total, sizes, prev, pager, next, jumper"
        :total=total
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    />

    <el-dialog v-model="dialogFormVisible" title="修改数据">
      <el-form :model="arrs">
        <el-form-item label="用户名" :label-width="formLabelWidth">
          <el-input v-model="arrs.username" autocomplete="off" />
        </el-form-item>
        <el-form-item label="权限" :label-width="formLabelWidth">
          <el-select v-model="arrs.permission" placeholder="Please select a zone">
            <el-option label="管理员" value="管理员" />
            <el-option label="普通用户" value="普通用户" />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click=update>
          修改
        </el-button>
      </span>
      </template>
    </el-dialog>
    <el-dialog v-model="showDialog" title="添加数据">
      <el-form :model="arrs">
        <el-form-item label="用户名" :label-width="formLabelWidth">
          <el-input v-model="arrs.username" autocomplete="off" />
        </el-form-item>
        <el-form-item label="真实名" :label-width="formLabelWidth">
          <el-input v-model="arrs.fullname" autocomplete="off" />
        </el-form-item>
        <el-form-item label="权限" :label-width="formLabelWidth">
          <el-select v-model="arrs.permission" placeholder="Please select a zone">
            <el-option label="管理员" value="管理员" />
            <el-option label="普通用户" value="普通用户" />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="showDialog = false">Cancel</el-button>
        <el-button type="primary" @click="submit">
          Confirm
        </el-button>
      </span>
      </template>
    </el-dialog>
  </div>



</template>


<script>
import axios from 'axios';
import request from "@/utils/request";
import {store} from "@/utils/store";


export default {

  // eslint-disable-next-line vue/multi-word-component-names
  name: 'user',

  data() {
    return {
      input:1,
      deleteuser:{
        username:''
      },
      loading: true,
      params:{
        username:'',
        fullname:'',
        pageNum:1,
        pageSize:5
      },
      config: {
        globalProperties: {
          $axios: axios
        }
      },
      items: [],
      visible:false,
      showDialog:false,
      formLabelWidth: '30%',
      dialogFormVisible:false,
      arrs:{},

      total:0
    };
  },
  methods: {
    async loadGet() {
      try {
      this.findSearch();

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    modifyItem(item){
      this.arrs=item;
      this.dialogFormVisible=true;


    },
    findSearch(){

      request.post("/user/get",this.params).then(res=>{

          if (res.code === '1') {
            this.items = res.data.list;
            this.total=res.data.total;


          } else {
            this.$message({
              message:res.msg,
              type: 'error'
            });
          }

      })

    },

    handleSizeChange(pageSize){
      this.params.pageSize=pageSize;
      this.findSearch()
    },
    handleCurrentChange(pageNum){
      this.params.pageNum=pageNum;
      this.findSearch()
    },
    getuser(item){
      this.arrs=item;
      this.dialogFormVisible=true;
    },
    update(){

      request.post('/user/save',this.arrs).then(res=>{
        if(res.code=='1'){

          this.$message({
            message: "修改成功",
            type: 'success'
          });
          this.arrs={}
          this.dialogFormVisible=false;
          this.findSearch();
        }else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })
    },
    submit(){

      request.post('/user/save',this.arrs).then(res=>{
        if(res.code=='1'){
          this.showDialog = false;
          this.$message({
            message: "添加成功",
            type: 'success'
          });

          this.findSearch();
          this.arrs={}
        }else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })

    },
    deleteItem(item){
      this.deleteuser=item;

     request.get('/user/delete',{params:this.deleteuser}).then(res=>{

       if(res.code==='1'){

         this.loadGet();
         this.$message({
           message:'删除成功',
           type:'success'
         });
       }
       else{
         this.loadGet();
       }
     })



      },
    exp(){
      location.href='https://hsq.org.cn:8090/hsq/user/export'
    }


  },
  computed: {
    resLoading() {
      return store.state.isLoading
    },
  },
  mounted() {
    this.loading=this.resLoading
    this.loadGet();


  }
}
//背景图，暂时不用
// body::after {
//   content: "";
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-image: url('https://api.vvhan.com/api/view');
//   background-repeat: repeat;
//   background-size: cover;
//   opacity: 0.17; /* 设置透明度，取值范围为0-1，0表示完全透明，1表示完全不透明 */
//   pointer-events: none; /* 禁止伪元素捕获鼠标事件，以允许页面操作 */
// }
</script>
<style>
#delete {
  position: relative;
}
</style>