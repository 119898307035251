<template>
  <div style="display:grid;grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));grid-gap: 10px; text-align: center;">
    <template v-for="item in novelList" :key="item.id" >
<div v-if="(item.typeName==noveltype||isall)&&item.ifopen=='true'" class="box-card" style="background-color: #efdede; margin-top: 10px;margin-right: 10px;display: flex;">
      <div v-if="(item.typeName==noveltype||isall)&&item.ifopen=='true'" style="padding: 10px">
        <el-image style="width: 150px; height: 180px;" :src="'https://hsq.org.cn:8090/hsq/file/' + item.cover"/>
      </div>

      <div v-if="(item.typeName==noveltype||isall)&&item.ifopen=='true'" style="width: 130px;margin: 10px;text-align: left">
        <a @click="navTo('/novelchapters?id='+item.id)">标题：{{ item.title }}</a>
        <p>作者：{{ item.username }}</p>
        <p>类型：{{ item.typeName }}</p>
        <p>简介：{{item.description}}</p>
        <div v-if="ratings && ratings.length">
          <p v-for="r in getRatingsByNid(item.id)" :key="r.id">
            评分: {{r.rating}}分
          </p>
        </div>
      </div>
</div>
    </template>
</div>
  <div style="align-items: center; justify-content: center; display: flex; height: auto; margin-top: 20px;"> <el-empty description="没有找到相关小说了"></el-empty> </div>
</template>

<script>
import router from "@/router";

export default {
  name: 'NovelCardList',
  props: {
    novelList: {
      type: Array,
      required: true,
    },
    ratings: {
      type: Array,
      default: () => [],
    },
    noveltype: {
      type: String,
      default: '小说',
    },
    isall: {
      type: Boolean,
      default: false,
    },
  },
  methods: {

    navTo(path) {
      // 假设这是你的导航逻辑，你可以根据实际需求调整
     router.push(path);
    },
    getRatingsByNid(nid) {
      // 假设ratings是组件外部传入的，这里根据nid过滤评分
      return this.ratings.filter(r => r.nid === nid);
    },


  },
};
</script>

<style scoped>
/* 样式可以放在这里 */
.novel-card-list {
  /* ... */
}

.box-card {
  /* ... */
}
</style>